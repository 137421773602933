@font-face {
	font-family: RobotoRegular;
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/roboto/woff2/Roboto-Regular.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('../fonts/roboto/woff/Roboto-Regular.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('../fonts/roboto/ttf/Roboto-Regular.ttf') format('truetype');
}
@font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/iransans/eot/IRANSansWeb.eot');
    src: url('../fonts/iransans/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('../fonts/iransans/woff2/IRANSansWeb.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../fonts/iransans/woff/IRANSansWeb.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('../fonts/iransans/ttf/IRANSansWeb.ttf') format('truetype');
}
body {
  margin: 0;
  padding: 0;
  font-family: 'RobotoRegular','IRANSans',-apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo-box {
  xborder-right: 1px solid #e8e8e8;
  padding: 14px;
}

.logo-box .logo {
  background: url('../image/logo.png') no-repeat center;
  background-size: contain;
  height: 35px;
}

.header-shadow {
  border-bottom: 1px solid #e8e8e8;
  -webkit-box-shadow:0px 1px 1px #e8e8e8;
 -moz-box-shadow:0px 1px 1px #e8e8e8;
 box-shadow:0px 1px 1px #e8e8e8;
}
.font-fa{
    font-family: IRANSans;
}
