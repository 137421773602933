body,a,input,button{
	text-transform: capitalize;
}
body::-webkit-scrollbar {
	width: 0.5em;
}

body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
	border-radius: 10px;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title,
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title,
.ant-form-explain, .ant-form-extra{
	font-size:12px !important;
}
.left {
	float: left
}
.right {
	float: right
}
.price{
	direction: ltr;
}
.ant-form label {
	font-weight: bold;
	font-size: 11px !important;
	padding-right: 10px;
}
.ant-input,.ant-select{
	font-size: 12px !important;
	margin-bottom: 10px !important;
}

.invalid{
	border: 1px solid red;
	border-radius: 4px;
}
.invalid,.invalid i svg {
	color: red;
}

.ant-form-item{
	font-size: 11px !important;
}
.has-success.has-feedback .ant-form-item-children-icon, .has-warning.has-feedback .ant-form-item-children-icon, .has-error.has-feedback .ant-form-item-children-icon, .is-validating.has-feedback .ant-form-item-children-icon{
	right: auto !important;
	left:0px !important;
}
form .has-feedback .ant-input {
	padding-left: 10px !important;
}
form .has-feedback > .ant-select .ant-select-arrow, form .has-feedback > .ant-select .ant-select-selection__clear, form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow, form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
	left: 10px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
	padding-right: 30px;
	padding-left: 10px !important;
}
.ant-input-affix-wrapper .ant-input-prefix {
	right: 12px;
	left: auto !important;
}
.featureTitle .ant-input-affix-wrapper .ant-input-suffix {
	right: 3px !important;
}
.ant-select-dropdown{
	font-size:12px !important;
}
.ant-select-dropdown-menu{
	padding-right: 0px;
}
.ant-form{
	background: #fff;
	padding:10px 20px !important ;
}
.ant-menu,.ant-layout-sider-light,.ant-layout-sider-light .ant-layout-sider-trigger {
	background: #5A4586 !important;
	color: #fff !important;
}
.ant-menu-submenu > .ant-menu {
	background-color: #fff !important;
}
.ant-menu-item > a,.ant-menu-item:hover, .ant-menu-item-active {
	color: #20242a !important;
}
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open
{
	color: #20242a !important;
}
.ant-menu-submenu-active, .ant-menu-submenu-title:hover,
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected,
.ant-menu-submenu-open.ant-menu-submenu-selected,
.ant-menu-submenu.ant-menu-submenu-selected{
	color: #DCC6E0 !important;
}
.ant-btn-primary {
	background-color: #6C2571 !important;
	border-color: #6C2571  !important;
}
a {
	/*color: #6C2571 !important;*/
}
.ant-table-small{
	border: none !important;
}
.ant-table-thead > tr:first-child > th:first-child {
	 border-top-rightt-radius: 4px !important;
 }
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th{
	background-color: #6C2571 !important;
	color: #fff;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
	background-image: none;
}
.ant-layout-header,.ant-layout-footer{
	background: #f0f2f5 !important;
}
.ant-layout-footer{
    text-align:right;
}
.ant-layout-header{
	height: auto !important;
}
.ant-layout{
	background: #fff !important;
}
.ant-divider-horizontal.ant-divider-with-text, .ant-divider-horizontal.ant-divider-with-text-left, .ant-divider-horizontal.ant-divider-with-text-right{
	color: #6C2571 !important;
}
.userListBtn {
	text-align: left;
	margin: 10px;
	float: left;
}
.formLabel{
	padding-top: 1%;
}
.addAddress{
	padding: 0 5px;
}
.addAddress button{
	margin-bottom: 30px;
}
.errorMsg{
	font-size: 11px;
	color: red;
	top: 5px;
	position: relative;
}
.warningMsg{
	font-size: 11px;
	color: yellow;
	top: 5px;
	position: relative;
}
.ant-select-selection__rendered {
	line-height: 25px !important;
}
a.ant-btn-primary{
	color: #fff !important;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link, .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
	background: inherit !important;
	border-color: #d9d9d9 !important;
}
.ant-pagination.mini .ant-pagination-item,.ant-pagination.mini .ant-pagination-prev, .ant-pagination.mini .ant-pagination-next{
	float: left;
}
.ant-pagination.mini .ant-pagination-item{
	margin: 0 5px !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions {
	background-clip: unset !important;
}
.klkssW .ant-table{
	font-size: 11px !important;
}
.listBtn{
	color: #fff !important;
	background: #FFC107;
	border-radius: 50%;
	padding: 3px 5px;
}
.blueListBtn{
	color: #fff !important;
	background: #06AAE9;
	border-radius: 50%;
	padding: 3px 5px;
}
.redListBtn{
	color: #fff !important;
	background: red;
	border-radius: 50%;
	padding: 3px 5px;
}
.greenListBtn{
	color: lightseagreen;
	font-size: 23px;
	margin-top: 10px;
	position: relative;
	top: 5px !important;
}
.greenListBtn2{
	color: #fff !important;
	background: lightseagreen;
	border-radius: 50%;
	padding: 1px 5px;
}
.grayListBtn{
	color: #fff !important;
	background: gray;
	border-radius: 50%;
	padding: 1px 5px;
}
.purpleListBtn{
	color: #fff !important;
	background: mediumpurple;
	border-radius: 50%;
	padding: 1px 5px;
}

.backBtn {
	color:#fff;
	margin-left: 10px;
	background: #1C1836 !important;
}
a.backBtn{
	color: #fff !important;
}
.backBtn2 {
	margin-left: 10px;
	margin-top: 20px;
	height: 26px !important;
	float: left;
}

.wizardLeftBtn{
	margin-right: 10px;
}

.ant-table-row-collapsed::after ,.ant-table-row-expanded::after{
	position: relative;
	top: 2px;
}
.ant-table-thead > tr > th .ant-table-column-sorter{
	color: #fff !important;
}
.ant-table-content{
	overflow-x: auto;
}
/*-------- pagination -----*/
/*.ant-pagination-options .ant-select{*/
/*	font-size: 11px;*/
/*}*/
/*.ant-pagination-options .ant-select-selection--single{*/
/*	height: 26px !important;*/
/*	margin-top: 10px !important;*/
/*}*/
/*.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next,.ant-pagination-item{*/
/*	min-width: 26px !important;*/
/*	height: 26px !important;*/
/*	line-height: 26px !important;*/
/*}*/

/*.ant-pagination-next{*/
/*	margin-right: 8px;*/
/*}*/
/*.ant-pagination li{*/
/*	font-family: inherit;*/
/*}*/
/*.ant-pagination-item-active {*/
/*	font-weight: normal !important;*/
/*	background: #49A9EE !important;*/
/*}*/
/*.ant-pagination-item-active a {*/
/*	color: #fff !important;*/
/*}*/
/*.ant-pagination{*/
/*	float:left;*/
/*	margin-top:20px !important;*/
/*}*/
.ant-pagination-item:not(.ant-pagination-item-active){
	border:1px solid #d9d9d9 !important
}
.countList{
	text-align: right;
	width: 100px !important;
	margin-top: 20px;
}
.countList .ant-select-selection{
	text-align: right;
	width: 70px !important;
	height: 27px;
}
/*--- upload ---*/
.ant-upload-select-picture-card i {
  font-size: 28px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  font-size: 12px;
  color: #666;
}
.chooseTech{
	width: 200px;
}
.productCount{
	margin-top:10px !important;
}
.descriptionWrapper{
	padding-bottom: 20px;
}
.descriptionBox{
	margin-top:5px;
	text-align: right;
	font-size: 13px;
}
/*----- pop confirm -----*/
.ant-popover-message > .anticon{
	right: 0px !important;
}
.ant-popover-message-title {
	padding-right: 22px !important;
	padding-left:0px !important
}
/*----- collapse -----*/
.extraInfoWrapper i{
	transform: rotate(180deg) !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
	padding: 8px 16px !important;
	font-size:13px !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
	top:38% !important;
}
.collapseText{
	font-size: 12px;
}
.ant-typography.ant-typography-success {
	color: #52c41a;
}
.even{
	background: #fcfcfc;
}
/*---- dashboard----*/
.dashboardIcon{
	font-size: 60px;
}
.greenIcon{
	color:#8493CB !important;
}
.blueIcon{
	color:#2db7f5 !important;
}
.purpleIcon{
	color:mediumpurple !important;
}

/*--- calendar---*/
.dashboardCalendar{
	width: 97% !important;
	float: left;
}
.shortcutBox{
	border-radius: 3px;
	text-align: center;
	padding: 35px 15px !important;
}
.shortcutBox a{
	color: #fff !important;
}
.shortcutBox i{
	color: #fff;
	font-size: 50px;
	margin-bottom: 45px;

}
.blueBox{
	background: #20A8D8;
	border-color: #187da0;
}
.yellowBox{
	background: #FFC107;
	border-color: #c69500;
}
.redBox{
	background: #F86C6B;
	border-color: #f5302e;
}
.lightBlueBox{
	background: #63C2DE;
	border-color: #46C7DE;
}
.grayBox{
	background: #C8CED3;
	border-color: #B3CFD3;
}
.greenBox{
	background: #4DBD74;
	border-color: #35BD70;
}
.shortcutBoxWrapper{
	padding-left: 8px;
}
/*---- sticky note ----*/
.react-stickies-wrapper .add{
	display: none;
}
.react-stickies-wrapper .note-body{
	height: 360px;
	overflow-y: auto;
}
/* width */
.note-body::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.note-body::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.note-body::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.note-body::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.datePicker {
	padding: 5px;
	margin-top: 5px;
	display: block;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-top-width: 1.02px;
	border-radius: 4px;
	outline: none;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 80%;
}
.settingsHelp{
	text-align: right;
	font-size: 10px;
	color: #52c41a;
	float: right;
}
/*---- sticky note ----*/
.note-wrapper{
	width: 98%;
	background: rgb(247, 209, 209);
	float: left;
	border-radius: 4px;
}
.note-text textarea{
	border:none;
	background: transparent;
}
.note-text textarea:focus{
	border:none !important;
	box-shadow: none !important;
}
.note-title{
	padding: 5px 0;
	background: rgba(0, 0, 0, 0.1);
	color: #747474;
}
.datepicker-input::placeholder{
	color: #bfbfbf;
	font-size: 12px;
}

.ant-dropdown-menu-item .anticon {
	margin-right: 10px !important;
	margin-left: 0 !important;
}
.searchBtnBox{
	margin-top: 5px;
	margin-left: 10px;
}
.ant-btn:hover, .ant-btn:focus{
	border: 1px solid transparent;
}
.colExpBtnBox .ant-btn:hover{
	color: #6C2571 !important;
	border: 1px solid #6C2571 !important;
}
.ant-spin{
	color: #6C2571 !important;
}
.ant-spin-dot-item{
	background-color: #6C2571 !important;
}
.ant-menu-submenu-arrow::before,.ant-menu-submenu-arrow::after{
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.65)), to(rgba(255, 255, 255, 0.65))) !important;
	background-image: linear-gradient(to right, rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.65)) !important;
	background: rgba(255, 255, 255, 0.65);
	background: #fff;
}
.categoryIcon{
	padding: 5px;
}
.featureTitle{
	font-size: 13px;
	padding-left: 10px;
	margin-top: 5px;
	height: 28px;
	overflow: hidden;
}
.featureLeft{
	border-radius: 3px 0 0 3px;
	padding: 3px 0;
	border:1px solid #d9d9d9;
}
.featureRight{
	border-radius: 0 3px 3px 0;
	padding: 2px 0;
	border:1px solid #d9d9d9;
	border-left: none;
}
.featureItem{
	background: #f6f6f6;
	padding: 5px 10px !important;
	border-radius: 5px;
}
.featureHead .ant-card-head-title{
	flex: 2 1;
}
.featureHead .ant-card-extra{
	flex: 2 1;
	float: right;
}
/*-------- carousel --------*/
.react-multi-carousel-item img{
	width:100%;
}
.thumbs-wrapper.axis-vertical,
.carousel-status{
	display:none
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
	opacity: 1 !important;
}
.carousel-root .control-arrow{
	margin-top:-10% !important;
}
.carousel .control-dots{
	top:75%
}
.iconBox{
	margin-bottom: 10px;
	float: left;
}
.iconBox .ant-tag{
	cursor: pointer;
}