@font-face {
    font-family: AntDesign;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/icons/AntDesign.ttf) format("truetype");
}
@font-face {
    font-family: Entypo;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/icons/Entypo.ttf) format("truetype");
}
@font-face {
    font-family: EvilIcons;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/icons/EvilIcons.ttf) format("truetype");
}
@font-face {
    font-family: Feather;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/icons/Feather.ttf) format("truetype");
}
@font-face {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/icons/FontAwesome.ttf) format("truetype");
}
@font-face {
    font-family: Foundation;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/icons/Foundation.ttf) format("truetype");
}
@font-face {
    font-family: Ionicons;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/icons/Ionicons.ttf) format("truetype");
}
@font-face {
    font-family: MaterialCommunityIcons;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/icons/MaterialCommunityIcons.ttf) format("truetype");
}
@font-face {
    font-family: MaterialIcons;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/icons/MaterialIcons.ttf) format("truetype");
}
@font-face {
    font-family: Octicons;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/icons/Octicons.ttf) format("truetype");
}
@font-face {
    font-family: SimpleLineIcons;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/icons/SimpleLineIcons.ttf) format("truetype");
}
@font-face {
    font-family: Zocial;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/icons/Zocial.ttf) format("truetype");
}
